import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import {
    ListingLayoutSingleColumn,
    ListingLayoutGalleryContact,
} from "@organic-return/foundation-react/src/components/Listing"
import MainPage from "./MainPage"
import { useSiteContext } from "./components/common/SiteContext"
import { Script } from "gatsby"

import axios from "axios"
import { getRecaptchaToken, getSegmentAnonymousId, registerSegmentActivity } from "./lib/utils"
import truncate from "truncate-html"

export const query = graphql`
  query($nodeID: String!) {
    listing: closedListing(id: { eq: $nodeID }) {
      id
      baths
      address {
        city
        county
        postal
        text
        street1
        street2
      }
      beds
      imagesJSON
      detailsJSON
      mlsID
      mlsSourceName
      mlsSourceLogoUrl
      price
      sqft
      status
      exclusive            
      defaultImage {        
        url
      }
      path      
      description
      tours {
        url
      }      
      location {
        lat
        lng
      }      
      officeName
      office {
        fullName
        phone
      }
      agent {
        mlsID
        first
        last
        fullName
        email                
        officeName
        phoneOffice
        photos {
          url
        }
        path
        phone
        phoneMobile          
      }      
    }
  }
`

const ClosedListingPage = ({ pageContext, data, location }: any) => {
    const siteContext = useSiteContext();
    const [listtracLoaded, setListtracLoaded] = useState(false)

    const Layout = data.listing.exclusive
        ? (siteContext.exclusiveListingLayout || ListingLayoutSingleColumn)
        : (siteContext.listingLayout || ListingLayoutGalleryContact)
    let layoutProps = data.listing.exclusive
        ? siteContext.exclusiveLayoutProps
        : siteContext.listingLayoutProps

    layoutProps?.formProps && (layoutProps.formProps.clickHandler = async (formInput: any) => {
        const recaptchaToken = await getRecaptchaToken(data.listing.exclusive ? "exclusive_listing" : "non_exclusive_listing");
        await axios({
            url: process.env.LEAD_CREATE_API_ENDPOINT,
            method: "POST",
            data: {
                recaptchaToken,
                formValues: formInput,
                propertyInfo: {
                    listingId: data.listing.mlsID,
                    listingAddress: data.listing.address.text,
                    listingPrice: data.listing.price,
                    listingAgentMlsId: data.listing.agent && data.listing.agent[0] ? data.listing.agent[0].replace("MFR", "") : null,
                    listingType: data.listing.type,
                    createdFrom: data.listing.exclusive ? `Property Page` : `Non Exclusive Property Page`,
                },
                segmentAnonymousId: getSegmentAnonymousId(),
            },
        })
        if (typeof (_LT) != 'undefined' && _LT != null) {
            _LT._trackEvent(_eventType.lead, data.listing.mlsID, data.listing?.address?.postal, data.agent?.mlsID, null, null, null);
        }
        if (process.env.GATSBY_LISTHUB_PROVIDER_ID) {
            sendListHubEmailEventSubmission(data.listing.mlsID, data.listing.addres?.postal, data.listing.exclusive)
        }
        registerSegmentActivity(`Contact Us Listing Page Form Submission`, formInput)
    });


    layoutProps?.contactUsFormProps && (layoutProps.contactUsFormProps.clickHandler = async formInput => {
        const recaptchaToken = await getRecaptchaToken("contact_us_listing");
        await axios({
            url: process.env.LEAD_CREATE_API_ENDPOINT,
            method: "POST",
            data: {
                recaptchaToken,
                formValues: formInput,
                propertyInfo: {
                    createdFrom: `Contact US Listing Page Form`,
                    listingId: data.listing.mlsID,
                    listingAddress: data.listing.address.text,
                    listingPrice: data.listing.price,
                    listingAgentMlsId: data.listing.agent && data.listing.agent[0] ? data.listing.agent[0].mlsID : null,
                    listingType: data.listing.type
                },
                segmentAnonymousId: getSegmentAnonymousId(),
            },
        })
        registerSegmentActivity(`Contact Us Listing Page Form Submission`, formInput)
    });

    data.listing.similar?.forEach((similar: any) => {
        !similar.defaultImage?.dimensions &&
            similar.defaultImage &&
            (similar.defaultImage.dimensions = [800, 600])
    })

    data.listing.office = data.listing.office ?? [];
    data.listing.agent = data.listing.agent ?? [];

    data.listing["details"] = JSON.parse(data.listing.detailsJSON)
    data.listing["images"] = JSON.parse(data.listing.imagesJSON)

    var pageTitle = `${data.listing.address?.text} #${data.listing.mlsID} - Real Estate`;
    var pageDescription = data.listing.description ? truncate(data.listing.description, 297) : "";

    return (
        <MainPage
            seoProps={pageContext.ignoreSEO ? {
                title: `Real Estate`
            } : {
                title: pageTitle,
                description: pageDescription,
                ogImage: data.listing.defaultImage?.url,
                ogType: "place",
                location: location
            }}
            headerProps={{ gradient: data.listing.exclusive, ...siteContext.headerProps }}
            className={`listing-page ${data.listing.exclusive ? "listing-exclusive" : ""
                }`}
        >
            <>
                <Layout
                    className={`mx-auto ${!data.listing.exclusive && "md:mt-28"}`}
                    listing={data.listing}
                    agents={data.listing.agent}
                    similar={data.listing.similar}
                    title={data.listing.address.text}
                    subtitle={data.listing.tagline}
                    linkFunc={props => {
                        return (
                            <Link
                                to={props.href ?? ""}
                                className={`hide-disabled text-inherit ${props.className}`}
                                key={props.key}
                                title={props.title}
                            >
                                {props.children}
                            </Link>
                        )
                    }}
                    {...layoutProps}
                />
                {process.env.LISTTRAC && (
                    <>
                        <Script
                            src={`https://code.listtrac.com/monitor.ashx?acct=${process.env.LISTTRAC}&nonjq=1`}
                            strategy="idle"
                            onLoad={() => setListtracLoaded(true)}
                        />
                        {listtracLoaded &&
                            <Script id="listtrac-track-event" strategy="idle">
                                {`
              if (typeof(_LT) != 'undefined' && _LT != null ) {
                _LT._trackEvent(_eventType.view, '${data.listing.mlsID}', ${data.listing?.address?.postal ? `'${data.listing.address.postal}'` : null}, ${data.agent?.mlsID ? `'${data.agent?.mlsID}'` : null}, null, null, null);
              }
            `}
                            </Script>}
                    </>
                )}
            </>
        </MainPage>
    )
}

export default ClosedListingPage
